import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { NavbarContainer } from './NavbarContainer'

export default class Navbar extends Component {
  render() {
    return (

      <NavbarContainer className="navbar navbar-expand-md navbar-dark fixed-top">
      <div className="container">
        <div className="d-flex flex-column d-lg-block d-md-block">
          <span className="mr-3"><i className="fas fa-envelope"></i> info@faidigirl.org</span>
          <span><i className="fas fa-phone"></i> +254 712 639788</span>
        </div>

        <div className="float-right">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="//facebook.com" target="_blank" className="nav-link active">
                  <i className="fab fa-facebook"></i>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="//twitter.com" target="_blank" className="nav-link active">
                  <i className="fab fa-twitter"></i>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="//instagram.com" target="_blank" className="nav-link active">
                  <i className="fab fa-instagram"></i>
                </Link>
              </li>
              <li className="d-sm-none">
                <Link to="/" className="nav-link text-capitalize">home</Link>
              </li>
              <li className="d-sm-none">
                <Link to="/" className="nav-link text-capitalize">about us</Link>
              </li>
              <li className="d-sm-none">
                <Link to="/" className="nav-link text-capitalize">our programs</Link>
              </li>
              <li className="d-sm-none">
                <Link to="/" className="nav-link text-capitalize">partners</Link>
              </li>
              <li className="d-sm-none">
                <Link to="/" className="nav-link text-capitalize">our stories</Link>
              </li>
              <li className="d-sm-none">
                <Link to="/" className="nav-link text-capitalize">media</Link>
              </li>
              <li className="d-sm-none">
                <Link to="/" className="nav-link text-capitalize">contact us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      </NavbarContainer>
    )
  }
}
