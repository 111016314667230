import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import './App.css';
import Home  from './components/Home'
import Navbar from './components/Navbar'

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    }
    this.notify = this.notify.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  notify() {
    console.log(`${this.state.email}: added to the list`)
    this.setState({
      email: ''
    })

  }
  handleChange(e) {
    e.preventDefault()
    this.setState({
      email: e.target.value
    })
  }
  render() {
    return (
      <React.Fragment>
        <Navbar></Navbar>
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      </React.Fragment>
    );
  }
}
