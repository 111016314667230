import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { NavHeaderContainer } from './NavbarContainer'

export default class Home extends Component {
  render() {
    return (
      <div className="container">
        <NavHeaderContainer>
          <nav>
            <div className="d-flex justify-content-between">
              <div>
                <img src="/img/logo.png"  alt="Logo" />
              </div>
              <div className="mt-4 d-none d-sm-block">
                <ul className="nav">
                  <li>
                    <Link to="/" className="nav-link">home</Link>
                  </li>
                  <li>
                    <Link to="/" className="nav-link">about us</Link>
                  </li>
                  <li>
                    <Link to="/" className="nav-link">our programs</Link>
                  </li>
                  <li>
                    <Link to="/" className="nav-link">partners</Link>
                  </li>
                  <li>
                    <Link to="/" className="nav-link">our stories</Link>
                  </li>
                  <li>
                    <Link to="/" className="nav-link">media</Link>
                  </li>
                  <li>
                    <Link to="/" className="nav-link">contact us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </NavHeaderContainer>
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="https://res.cloudinary.com/faidigirl/image/upload/v1571661133/1_weqlag.jpg" className="d-block w-100" height="350px" alt="carousel 1" />
            </div>
            <div className="carousel-item">
              <img src="https://res.cloudinary.com/faidigirl/image/upload/v1571661133/2_cpjqlt.jpg" className="d-block w-100" height="350px" alt="carousel 2" />
            </div>
            <div className="carousel-item">
              <img src="https://res.cloudinary.com/faidigirl/image/upload/v1571661133/3_clrbtk.jpg" className="d-block w-100" height="350px" alt="carousel 3" />
            </div>
            <div className="carousel-item">
              <img src="https://res.cloudinary.com/faidigirl/image/upload/v1571661133/4_emlyuz.jpg" className="d-block w-100" height="350px" alt="carousel 4" />
            </div>
            <div className="carousel-item">
              <img src="https://res.cloudinary.com/faidigirl/image/upload/v1571661133/5_ogpcaa.jpg" className="d-block w-100" height="350px" alt="carousel 5" />
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    )
  }
}
