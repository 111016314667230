import styled from 'styled-components'

export const NavbarContainer = styled.nav`
  background-color: var(--mainPurple);
  backdrop-filter: saturate(180%) blur(20px);
  color: var(--mainWhite);

  a {
    color: #e7e7e7;
    transition: ease-in-out color .15s;
  }

  a:hover {
    color: var(--mainWhite);
    text-decoration: none;
  }
`

export const NavHeaderContainer = styled.div`
  margin-top: 3em;

  a {
    color: var(--mainDark);
    font-weight: 600;
    text-transform: uppercase;
  }

  a:hover {
    color: var(--mainYellow);
  }
`
